<template>
  <section
    class="osk-home-app-desktop"
  >
    <div class="osk-wrapper">
      <h2
        v-if="!hideTitle"
        class="text-heading text-center text-uppercase"
      >
        {{ $t('home.downloadAppDesktop.title') }}
      </h2>

      <div class="osk-home-app-desktop__content osk-mt-xxxl osk-pl-xxxl">
        <slot name="qr-code" />

        <div class="osk-home-app-desktop__content-info osk-ml-lg">
          <div class="text-heading">
            {{ $t('home.downloadAppDesktop.header', { sale }) }}
          </div>

          <div class="text-subheading osk-mt-cp">
            {{ $t('home.downloadAppDesktop.text', { promoCode }) }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="osk-home-app-desktop__img"
    >
      <img
        v-if="!isInternational"
        src="~/assets/img/home/download-app.png"
        srcset="~/assets/img/home/download-app.png, ~/assets/img/home/download-app-2x.png 2x"
        alt="download-app"
      >
      <img
        v-else
        src="~/assets/img/home/download-app-int.png"
        alt="download-app"
      >
    </div>
  </section>
</template>

<script setup lang="ts">
defineProps<{
  hideTitle?: boolean
}>();
const { promoCode, sale } = useWebToApp();
const { isInternational } = useInternational();
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-home-app-desktop {
  max-width: 1232px;
  margin: 72px auto 0;
  position: relative;

  &__content {
    display: flex;
    align-items: center;
    justify-self: flex-start;
    height: 311px;
    background: url('../../assets/img/home/download-app-bg.webp') no-repeat center/100%;
  }

  &__content-info {
    max-width: 358px;
  }

  &__qr {
      width: 124px;
      height: 124px;
  }

  &__img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 613px;
  }
}
</style>
